import _parser from "./parser";
import _serializer from "./serializer";
var exports = {};
const Parser = _parser;
const Serializer = _serializer; // Shorthands

exports.parse = function parse(html, options) {
  const parser = new Parser(options);
  return parser.parse(html);
};

exports.parseFragment = function parseFragment(fragmentContext, html, options) {
  if (typeof fragmentContext === "string") {
    options = html;
    html = fragmentContext;
    fragmentContext = null;
  }

  const parser = new Parser(options);
  return parser.parseFragment(html, fragmentContext);
};

exports.serialize = function (node, options) {
  const serializer = new Serializer(node, options);
  return serializer.serialize();
};

export default exports;
export const parse = exports.parse,
      parseFragment = exports.parseFragment,
      serialize = exports.serialize;